import React from 'react';
import { Link } from 'gatsby';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import {
  makeStyles,
  createMuiTheme,
  ThemeProvider,
} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import LinkExternal from '@material-ui/core/Link';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#191f23',
  },
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  footer: {
    display: 'flex',
    padding: theme.spacing(1, 2),
    marginTop: theme.spacing(4),
    width: '100%',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  nav: {
    display: 'flex',
    flexFlow: 'row wrap',
    '@media (max-width: 1003px)': {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  navItem: {
    margin: '20px 20px',
    color: '#FFFFFF',
    fontWeight: '800',
    letterSpacing: '2px',
    '@media (max-width: 1003px)': {
      margin: '10px',
      display: 'none',
    },
  },
  socialMedia: {
    '@media (max-width: 1003px)': {
      margin: '0',
    },
  },
  contactItem: {
    fontWeight: '800',
    margin: '10px',
    color: '#FFFFFF',
  },
  item: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: '#FFFFFF',
  },
}));

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#FFFFFF',
    },
  },
});

function Copyright() {
  return (
    <Typography variant="body2" style={{ color: '#FFFFFF' }}>
      Copyright &copy; {new Date().getFullYear()}
      <LinkExternal color="inherit" href="https://rrsautogroup.com/">
        {' '}
        RRS Auto Group
      </LinkExternal>
    </Typography>
  );
}

const StickyFooter = props => {
  const classes = useStyles();
  const {
    email,
    phoneNumber,
    address,
    hours,
    facebookUrl,
    instagramUrl,
  } = props;

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
        <div className={classes.footer}>
          <div className={classes.nav}>
            <Typography
              className={classes.navItem}
              color="primary"
              variant="body2"
            >
              <Link
                to="/about-us"
                style={{ textDecoration: 'none', color: '#FFFFFF' }}
              >
                ABOUT
              </Link>
            </Typography>
            <Typography
              className={classes.navItem}
              color="primary"
              variant="body2"
            >
              <LinkExternal
                color="inherit"
                style={{ textDecoration: 'none' }}
                href="https://www.autotrader.com/car-dealers/San+Jose+CA-95131/70909855/RRS+Auto+Group"
                target="_blank"
                rel="noopener noreferrer"
              >
                INVENTORY
              </LinkExternal>
            </Typography>
            <Typography
              className={classes.navItem}
              color="primary"
              variant="body2"
            >
              <Link
                to="/consignment"
                style={{ textDecoration: 'none', color: '#FFFFFF' }}
              >
                CONSIGNMENT
              </Link>
            </Typography>

            <Typography
              className={classes.navItem}
              color="primary"
              variant="body2"
            >
              <LinkExternal
                color="inherit"
                style={{ textDecoration: 'none' }}
                href="https://www.linkedin.com/in/steven-k-shee-60bbb75"
                target="_blank"
                rel="noopener noreferrer"
              >
                STEVE&#39;S CORNER
              </LinkExternal>
            </Typography>
            <Typography
              className={classes.navItem}
              color="primary"
              variant="body2"
            >
              <Link
                to="/about-henri-richard"
                style={{ textDecoration: 'none', color: '#FFFFFF' }}
              >
                HENRI&#39;S CORNER
              </Link>
            </Typography>
            <Typography
              className={classes.navItem}
              color="primary"
              variant="body2"
            >
              <Link
                to="/contact"
                style={{ textDecoration: 'none', color: '#FFFFFF' }}
              >
                CONTACT US
              </Link>
            </Typography>
          </div>
          <div className={classes.socialMedia}>
            <LinkExternal
              color="inherit"
              style={{ textDecoration: 'none' }}
              href={instagramUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <InstagramIcon
                style={{ margin: '0 20px', color: '#FFFFFF' }}
                fontSize="large"
              />
            </LinkExternal>
            <LinkExternal
              color="inherit"
              style={{ textDecoration: 'none' }}
              href={facebookUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FacebookIcon
                style={{ margin: '0 20px', color: '#FFFFFF' }}
                fontSize="large"
                color="primary"
              />
            </LinkExternal>
          </div>
          <LinkExternal
            color="inherit"
            style={{ textDecoration: 'none' }}
            href="https://www.google.com/maps/place/RRS+Auto+Group+-+Dealership+and+Sports+Car+Storage/@37.3840472,-121.9166097,17z/data=!3m1!4b1!4m5!3m4!1s0x808fcbe4bc3b830f:0x6b383222a2debd4!8m2!3d37.384043!4d-121.914421"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Typography
              className={classes.contactItem}
              color="primary"
              variant="body2"
            >
              {address}
            </Typography>
          </LinkExternal>
          <LinkExternal
            color="inherit"
            style={{ textDecoration: 'none' }}
            href={`tel: ${phoneNumber}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Typography
              className={classes.contactItem}
              color="primary"
              variant="body2"
            >
              {phoneNumber}
            </Typography>
          </LinkExternal>
          <LinkExternal
            color="inherit"
            style={{ textDecoration: 'none' }}
            href={`mailto: ${email}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Typography
              className={classes.contactItem}
              color="primary"
              variant="body1"
            >
              {email}
            </Typography>
          </LinkExternal>
          <Typography
            className={classes.contactItem}
            color="primary"
            variant="body2"
          >
            {hours}
          </Typography>
        </div>
        <hr style={{ width: '50%', border: '0.75px solid #707070' }} />
        <footer>
          <Container maxWidth="xl" className={classes.item}>
            <Typography variant="body2" />
            <Copyright />
          </Container>
        </footer>
      </div>
    </ThemeProvider>
  );
};

export default StickyFooter;
