import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';

import Header from '../header';
import Footer from '../footer';
import MobileBar from '../mobile-bar';
import './index.module.css';

const query = graphql`
  query {
    contactUsCard: contentfulCard(
      contentful_id: { eq: "2fFL0j2RvlsVywQfT0Mmim" }
    ) {
      title
      subtitle
    }
    contactInfo: contentfulBusinessInformation(
      contentful_id: { eq: "7sUPvaBmu9HSe9ASYCz4CC" }
    ) {
      email
      phoneNumber
      address
      facebookUrl
      instagramUrl
      hours
    }
  }
`;

const Layout = ({ children }) => {
  const { contactInfo } = useStaticQuery(query);
  let isMobile = false;
  if (typeof window !== 'undefined') {
    isMobile =
      !!navigator.userAgent.match(/iphone|android|blackberry/gi) || false;
  }

  const [isNavigationOpen, setNavigation] = useState(false);

  function toggleNavigation() {
    setNavigation(!isNavigationOpen);
  }

  return (
    <>
      <Header toggleNavigation={toggleNavigation} />
      <div style={{ marginTop: '64px' }}>
        <main>{children}</main>
      </div>

      <Footer
        email={contactInfo.email}
        phoneNumber={contactInfo.phoneNumber}
        address={contactInfo.address}
        hours={contactInfo.hours}
        facebookUrl={contactInfo.facebookUrl}
        instagramUrl={contactInfo.instagramUrl}
      />
      {isMobile && (
        <MobileBar
          isOpen={isNavigationOpen}
          toggleNavigation={toggleNavigation}
        />
      )}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
