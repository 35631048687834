import { Link } from 'gatsby';
import React from 'react';
import { Location } from '@reach/router';

import Logo from '../../assets/logo.svg';
import Hamburger from '../../assets/hamburger.svg';
import styles from './index.module.css';

const classNameBuilder = (route, isMatch) => {
  return `${styles.item} ${
    route === isMatch || route === `${isMatch}` ? styles.active : ''
  }`;
};

const Header = ({ toggleNavigation }) => (
  <Location>
    {({ location: { pathname } }) => (
      <header>
        <div className={styles.navContainer}>
          <div className={styles.container}>
            <div className={styles.items}>
              <Link
                to="/about-us"
                className={classNameBuilder(pathname, '/about-us')}
              >
                About Us
              </Link>
              <a
                href="https://www.autotrader.com/car-dealers/San+Jose+CA-95131/70909855/RRS+Auto+Group"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.item}
              >
                Inventory
              </a>
              <Link
                to="/consignment"
                className={classNameBuilder(pathname, '/consignment')}
              >
                Consignment
              </Link>
              <Link to="/">
                <Logo className={styles.logo} />
              </Link>
              <a
                href="https://oloiinc.com/blogs/news/steve-shees-2001-lamborghini-diablo-vt-6-0"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.item}
              >
                Steve&#39;s Corner
              </a>
              <Link
                to="/about-henri-richard"
                className={classNameBuilder(pathname, '/about-henri-richard')}
              >
                Henri&#39;s Corner
              </Link>
              <Link
                to="/contact"
                className={classNameBuilder(pathname, '/contact')}
              >
                Contact Us
              </Link>
            </div>
          </div>
          <Hamburger className={styles.hamburger} onClick={toggleNavigation} />
        </div>
      </header>
    )}
  </Location>
);

export default Header;
