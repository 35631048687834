import React from 'react';
import { navigate } from '@reach/router';
import CloseButton from '../../assets/close.svg';

import styles from './index.module.css';

const MobileBar = ({ isOpen, toggleNavigation }) => {
  return (
    <div
      onClick={toggleNavigation}
      className={`${styles.mobileBarContainer} ${isOpen ? '' : styles.closed}`}
    >
      <div
        className={styles.mobileBarContent}
        style={{ width: isOpen ? '230px' : '0' }}
      >
        <div style={{ color: 'white' }}>
          <CloseButton
            className={styles.closeButton}
            onClick={toggleNavigation}
            style={{ opacity: isOpen ? '1' : '0' }}
          />
          <div
            className={styles.linksContainer}
            style={{ display: isOpen ? 'flex' : 'none' }}
          >
            <a
              className={styles.link}
              onClick={() => {
                toggleNavigation();
                navigate('/');
              }}
            >
              Home
            </a>
            <a
              className={styles.link}
              onClick={() => {
                toggleNavigation();
                navigate('/about-us');
              }}
            >
              About
            </a>
            <a
              className={styles.link}
              href="https://www.autotrader.com/car-dealers/San+Jose+CA-95131/70909855/RRS+Auto+Group"
              target="_blank"
              rel="noopener noreferrer"
            >
              Inventory
            </a>
            <a
              className={styles.link}
              onClick={() => {
                toggleNavigation();
                navigate('/consignment');
              }}
            >
              Consignment
            </a>
            <a
              className={styles.link}
              href="https://oloiinc.com/featured-steve-shees-2001-lamborghini-diablo-vt-6-0/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Steve&#39;s Corner
            </a>
            <a
              className={styles.link}
              onClick={() => {
                toggleNavigation();
                navigate('/about-henri-richard');
              }}
            >
              Henri&#39;s Corner
            </a>
            <a
              className={styles.link}
              onClick={() => {
                toggleNavigation();
                navigate('/contact');
              }}
            >
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileBar;
